import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

const Quiz = ({ ansubmit, questions, currentQuestion, answer, correct, setuAnswer, uAnswer, Qcount, prevQuestion, prevuAnswer, prevAnswer }) => {

    return (
        <>{(() => {
            return (
                <>
                    <h2>{questions[currentQuestion][0].replace(answer, "○○")}</h2>
                    <Form.Group className="mb-3" controlId="forAnswerbox">
                        <Row>
                            <Col xs="auto">
                                <Form.Control type="text" placeholder="解答を入力" value={uAnswer} onChange={(e) => setuAnswer(e.target.value)} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Button variant="primary" onClick={() => ansubmit()}>
                        解答
                    </Button></>)
        })()}
            {(() => {
                if (Qcount > 0 && (prevuAnswer === prevAnswer || (prevuAnswer.substring(1, 2) === "々" && prevuAnswer.substring(0, 1) + prevuAnswer.substring(0, 1) === prevAnswer))) {
                    return (<><hr></hr>
                        <p style={{ color: "red", fontWeight: 'bold' }}>正解です。</p></>)
                }
                else if (Qcount > 0 && prevuAnswer !== prevAnswer) {
                    return (<><hr></hr>
                        <p style={{ color: "blue", fontWeight: 'bold' }}>不正解です。</p></>)
                }
            })()}
            {(() => {
                if (Qcount > 0) {
                    return (<>
                        <ruby style={{ 'font-size': '1.5em' }}>{questions[prevQuestion][0]}<rt>{questions[prevQuestion][1]}</rt></ruby>
                        <p>{questions[prevQuestion][2]}</p>
                        <Table striped bordered className="autotable">
                            <tbody>
                                <tr>
                                    <td>前問の正解</td>
                                    <td>{prevAnswer}</td>
                                </tr>
                                <tr>
                                    <td>あなたの解答</td>
                                    <td>{prevuAnswer}</td>
                                </tr>
                                <tr>
                                    <td>現在の正答数</td>
                                    <td>{correct}/{Qcount}問({Math.round((correct / Qcount * 100) * 10) / 10}%)</td>
                                </tr>
                            </tbody>
                        </Table>
                    </>)
                }
            })()}
        </>
    );
};

export default Quiz;