import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from 'react-bootstrap';
import '../App.css';

const Header = () => {
    return (
        <Navbar className="header_style" variant="light" fixed="top" expand="md">
            <Navbar.Brand className="header_title">漢検対策</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/">最初から</Nav.Link>
                    <Nav.Link href="https://www.kanken.or.jp/" target="_blank">漢検協会HP</Nav.Link>
                    <Nav.Link href="https://www.kanken.or.jp/kanken/" target="_blank">漢検HP</Nav.Link>
                    <Nav.Link href="./yojijukugo.xlsx" target="_blank">2級四字熟語一覧Excelファイル</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;