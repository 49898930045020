import './App.css';
import React, { useState } from 'react';
import Header from "./components/header";
import Quiz from "./components/quiz";
import Alert from 'react-bootstrap/Alert';

function App() {
  const questions = [
    ['前代未聞', 'ぜんだいみもん', 'あまりにもふつうと違っていて，今まできいたことのないこと。'],
    ['人跡未踏', 'じんせきみとう', '人がまだ足を踏み入れたことがないこと。'],
    ['大胆不敵', 'だいたんふてき', '精神力が強く，決して恐れることがないこと。'],
    ['公明正大', 'こうめいせいだい', '私心を差し挟むことなく，公平に堂々と物事を行うこと。または，そのような様子。'],
    ['狂喜乱舞', 'きょうきらんぶ', '思い切り喜ぶこと。'],
    ['怪力乱神', 'かいりきらんしん', '人の知識では理解することが出来ない，怪しく奇怪な現象や物事のこと。'],
    ['傍若無人', 'ぼうじゃくぶじん', '公の場であるにもかかわらず，周りに人がいないかのように，他人の迷惑を考えない好き勝手な行動をすること。'],
    ['感慨無量', 'かんがいむりょう', '計ることが出来ないほど，深く感じ入ること。'],
    ['悠悠自適', 'ゆうゆうじてき', '俗世間を退いて，のんびりと日々を過ごすこと。定年退職後の生活などをいう。'],
    ['適者生存', 'てきしゃせいぞん', '環境に適応できた生物のみが生き残り，適応できない生物は淘汰されて絶滅すること。'],
    ['籠鳥恋雲', 'ろうちょうれんうん', '捕らえられている者が自由を望むことのたとえ。'],
    ['雲散霧消', 'うんさんむしょう', '雲や霧が消えてなくなるように，何かがそこにあった形跡が全て消えてなくなること。'],
    ['迅速果断', 'じんそくかだん', '物事を思い切りよく速やかに決断し，行動すること。'],
    ['因果応報', 'いんがおうほう', 'よい行いには必ずよい報いがあり，悪い行いには必ず悪い報いがあるということ。'],
    ['粉骨砕身', 'ふんこつさいしん', '力の限り努力すること。非常に苦労して働くこと。'],
    ['換骨奪胎',
      'かんこつだったい',
      '詩文の創作法の一つで，昔の人が作った詩文の表現や発想を取り入れ，それに自身の発想を加えて新しいものを作り出すこと。'],
    ['明鏡止水', 'めいきょうしすい', '心が清く澄み切っていて邪念のない心境のたとえ。'],
    ['山紫水明', 'さんしすいめい', '自然の景観がとても澄み切っていて美しいこと。'],
    ['秋霜烈日', 'しゅうそうれつじつ', '刑罰・意志・権威などがきわめてきびしく強いことのたとえ。草木を枯らすほど激しく厳しいもの。'],
    ['日進月歩', 'にっしんげっぽ', '日ごと月ごとに，とどまることなく進歩していくこと。'],
    ['泰然自若', 'たいぜんじじゃく', '何が起きても，おちつきはらって物事に動じない。安らかでもとのまま変化せず平気な様子。'],
    ['天下泰平', 'てんかたいへい', '世の中が極めて穏やかに治まっていて平和であること。'],
    ['抜山蓋世', 'ばつざんがいせい', '気力が充実していて，盛んな勢いがあること。'],
    ['方底円蓋', 'ほうていえんがい', '物事が上手くかみ合わないことのたとえ。'],
    ['雲泥万里', 'うんでいばんり', '天と地が遠く隔るように，比較にならないほどの差異のこと。極端に違うもののたとえ。'],
    ['悪事千里', 'あくじせんり', '悪い噂や悪い評判はすぐに遠くまで知れ渡るということ。'],
    ['破綻百出', 'はたんひゃくしゅつ', '喋れば喋るほどぼろが出ること。'],
    ['再三再四', 'さいさんさいし', '「たびたび」や「何度も何度も」という意味。'],
    ['千載一遇', 'せんざいいちぐう', '千年に一度あるかないかの絶好の機会のこと。'],
    ['岡目八目', 'おかめはちもく', '当事者よりも，はたから見ている人のほうが情勢を正しく判断できることのたとえ。'],
    ['九牛一毛', 'きゅうぎゅういちもう', 'たくさんある中のほんの少しの部分。'],
    ['千客万来', 'せんきゃくばんらい', 'たくさんの客が，途絶えることなく次々に来ること。'],
    ['冷汗三斗', 'れいかんさんと', '冷や汗が三斗出るということから，きわめて恐ろしい思いをすることや恥ずかしい思いをすること。'],
    ['一筆勾消', 'いっぴつこうしょう', '今までのものを全て取り消すこと。'],
    ['五里霧中', 'ごりむちゅう', '物事の状況が全くわからず，判断に困ること。または，そのような状況。'],
    ['三拝九拝', 'さんぱいきゅうはい', '何度も頭を下げて，頼みごとをしたり，敬意や謝罪の意志を表したりすること。'],
    ['九分九厘', 'くぶくりん', 'ほとんど完璧なもの。ほぼ間違いないこと。'],
    ['七転八倒', 'しちてんばっとう', '激しい苦痛に苦しみもだえること。'],
    ['十人十色', 'じゅうにんといろ', '性格，好み，考え方などは人それぞれ違うということ。'],
    ['二律背反', 'にりつはいはん', '互いに矛盾している二つの命題が，互いに同じだけの合理性や整合性があること。'],
    ['六根清浄', 'ろっこんせいじょう', '欲望や迷いを断ち切り，心と体を清らかにすること。'],
    ['三位一体', 'さんみいったい', '三つのものがしっかりと結びついて，一つのもののようになること。または，三人が心を一つにして物事にあたること。'],
    ['風流三昧', 'ふうりゅうざんまい', '自然を親しんで，詩歌や書画などの高尚な遊びに夢中になること。'],
    ['面壁九年', 'めんぺきくねん', '長い期間一つのことに専念してやり遂げること。'],
    ['名詮自性', 'みょうせんじしょう', '本質に名が相応しいもののこと。'],
    ['刻苦勉励', 'こっくべんれい', '苦労しながらも，全力で仕事や勉強に励むこと。'],
    ['孤立無援', 'こりつむえん', 'たった一人で，誰かの助けも期待できないこと。'],
    ['呉越同舟', 'ごえつどうしゅう', '仲の悪い者同士が，同じ場所にいたり，同じ境遇にいたりすること。'],
    ['温故知新', 'おんこちしん', '以前に学習したことや昔の事柄を今一度よく考察して，そこから新たな道理や知識を得ること。'],
    ['酒池肉林', 'しゅちにくりん', '極めて贅沢で豪勢な宴会のこと。'],
    ['疾風迅雷', 'しっぷうじんらい', '行動がすばやく激しいさま。非常にはやい風と激しい雷。'],
    ['鶏口牛後', 'けいこうぎゅうご', '大きな組織に入って上の人に付き従い言いなりになるより，小さな組織だとしても上に立つほうがよいということ。'],
    ['単刀直入', 'たんとうちょくにゅう', 'いきなり本題に入って，重要なことを追及すること。'],
    ['佳人薄命', 'かじんはくめい', '美しい女性は運に恵まれずに不幸せなことが多いという意味。'],
    ['厚顔無恥', 'こうがんむち', '恥知らずで，遠慮がない態度のこと。'],
    ['軽挙妄動', 'けいきょもうどう', '事の是非をわきまえず，でたらめな感じで軽々しく行動すること。'],
    ['有為転変',
      'ういてんぺん',
      'この世の全ての現象や存在は常に移り変わり，一定しているものはないということ。または，この世は儚いものであるということのたとえ。'],
    ['多岐亡羊',
      'たきぼうよう',
      '進路や方針が多すぎて何を選ぶべきかわからなくなること。または，学問の道が細かく分かれすぎていて，学問の根本を捉えにくいことのたとえ。'],
    ['内憂外患', 'ないゆうがいかん', '国内の心配事と，外国からしかけられるわずらわしい事態。また，個人における内外の心配事もいう。'],
    ['一目瞭然', 'いちもくりょうぜん', 'ほんの少し見ただけでも，はっきりとわかること。'],
    ['付和雷同', 'ふわらいどう', '自分の考えなどなく，周りの意見にむやみに同調すること。'],
    ['執行猶予',
      'しっこうゆうよ',
      '有罪判決を受けた人が情状によって，一定の期間刑罰を執り行わずに，その期間に何の問題も起こさなかった時には刑罰を執り行わないという制度のこと。'],
    ['痩骨窮骸', 'そうこつきゅうがい', '老いて衰えた体のこと。'],
    ['栄枯盛衰', 'えいこせいすい', '栄えたり衰えたりすること。'],
    ['苛政猛虎', 'かせいもうこ', '悪政は人を食べる虎よりも人々を苦しめるということ。'],
    ['時期尚早', 'じきしょうそう', 'あることを行うにはまだ時期が早すぎること。'],
    ['喜怒哀楽', 'きどあいらく', '「喜び」「怒り」「悲しみ」「楽しみ」といった，人間がもっているいろいろな感情のこと。'],
    ['堅忍不抜', 'けんにんふばつ', '意志を堅く持ち，つらいことでもじっと耐え忍んで心を動かさないこと。'],
    ['支離滅裂', 'しりめつれつ', 'まとまりがなく，ばらばらであること。'],
    ['一網打尽', 'いちもうだじん', '悪人や犯罪者などの集団を一気に全て捕まえること。'],
    ['怒髪衝天', 'どはつしょうてん', '激しく怒ること。または，その表情。'],
    ['傲岸不遜', 'ごうがんふそん', 'おごりたかぶって，人に従わず，見下す様子。'],
    ['忙中有閑', 'ぼうちゅうゆうかん', 'どんなに忙しいときでも，一息つくくらいの暇はあること。'],
    ['勧善懲悪', 'かんぜんちょうあく', '善行を勧め励まし，悪行を懲らしめ，悪い行いをしないようにしむけること。'],
    ['大喝一声', 'だいかついっせい', '大声で叱りつけたり，どなりつけたりすること。'],
    ['異国情緒', 'いこくじょうちょ', 'いかにも外国らしい風物がかもしだす気分や雰囲気｡'],
    ['一騎当千', 'いっきとうせん', '並外れた能力を持つ勇敢な人のこと。'],
    ['眺望絶佳', 'ちょうぼうぜっか', '目の前の風景がすばらしい様子。'],
    ['半醒半睡', 'はんせいはんすい', '意識がはっきりしていない状態。'],
    ['難攻不落', 'なんこうふらく', '非常に攻めることが難しく，容易には陥落しないこと。'],
    ['大悟徹底', 'だいごてってい', '欲望や迷いなどの煩悩を全て捨てて悟りを得ること。'],
    ['面目躍如', 'めんもくやくじょ', '世間の期待通りの活躍をして，いきいきとしている様子。'],
    ['温厚篤実', 'おんこうとくじつ', '心が温かく穏やかで，誠実な性格のこと。'],
    ['天涯孤独', 'てんがいこどく', '身寄りがなくひとりぽっちであること。また異郷にただ独りで暮らすこと。'],
    ['一陽来復', 'いちようらいふく', '悪い傾向にあった物事がよい傾向に向かうこと。'],
    ['同工異曲', 'どうこういきょく', '手法や技術に大きな差はないが，見た目は違っていること。'],
    ['破邪顕正', 'はじゃけんせい', '不正を打破して，正しい行いを示して守ること。'],
    ['堅塞固塁', 'けんさいこるい', '並外れて守りの堅い砦。'],
    ['荒唐無稽', 'こうとうむけい', '根拠も何もなく非現実的なこと。'],
    ['綱紀粛正', 'こうきしゅくせい', '乱れた規律を正すこと。国家の規律・政治の方針や，政治家・役人の態度を正すこと。'],
    ['鯨飲馬食', 'げいいんばしょく', '一度の食事で非常に多くのものを食べたり飲んだりすること。'],
    ['閑話休題', 'かんわきゅうだい', 'それはさておき。文章で，余談をやめて話を本題に戻すときに，接続詞的に用いる語。'],
    ['疑心暗鬼', 'ぎしんあんき', '疑う心を持ってしまい，取るに足らないことを恐れたり，怪しく感じたりしてしまうこと。'],
    ['深謀遠慮', 'しんぼうえんりょ', '遠い先のことまで深く考えて，しっかりとした計画を立てること。'],
    ['自縄自縛', 'じじょうじばく', '自分自身の心がけや言動によって，動きがとれなくなり，苦しむことのたとえ。'],
    ['不偏不党', 'ふへんふとう', 'どちらにもかたよらず公平中立の立場に立つこと。'],
    ['森羅万象', 'しんらばんしょう', '天地の間に存在するすべての事物・現象。'],
    ['煎水作氷', 'せんすいさくひょう', '絶対に不可能なこと。'],
    ['清廉潔白', 'せいれんけっぱく', '心が清らかで，良心に恥じるようなことがまったくないこと。'],
    ['断崖絶壁', 'だんがいぜっぺき', '非常に険しい崖のこと。'],
    ['当代随一', 'とうだいずいいち', '現代で最もすぐれているということの形容。'],
    ['遮二無二', 'しゃにむに', 'がむしゃら。前後の見境なく，強引に行なうこと。'],
    ['隠忍自重', 'いんにんじちょう', '苦しみなどをじっと我慢して，軽々しい行動をしないこと。'],
    ['熟読玩味', 'じゅくどくがんみ', '文章をしっかりと読んで，その意味を落ち着いてゆっくりと考えて味わうこと。'],
    ['正真正銘', 'しょうしんしょうめい', 'まったくうそ偽がなく本物であること。'],
    ['妙計奇策', 'みょうけいきさく', '他の人の思いつかないような奇抜ですぐれた計略。'],
    ['容姿端麗', 'ようしたんれい', '顔立ちも体形も整っていて美しいこと。普通は女性に使う。'],
    ['吟風弄月', 'ぎんぷうろうげつ', '自然の景色を題材にして，詩を詠むこと。'],
    ['相互扶助', 'そうごふじょ', 'お互いに助け合い支え合うこと。'],
    ['新陳代謝', 'しんちんたいしゃ', '古いものが新しいものへと入れ替わること。'],
    ['極楽浄土', 'ごくらくじょうど', '阿弥陀仏がいるとされている苦労や苦痛のない世界のこと。'],
    ['詩歌管弦', 'しいかかんげん', '文学と音楽のこと。漢詩や和歌と管楽器と弦楽器のこと。'],
    ['群雄割拠', 'ぐんゆうかっきょ', '実力者たちがそれぞれ勢力を持ち，対立すること。'],
    ['天衣無縫', 'てんいむほう', '詩や文章などにわざとらしさがなく，自然で美しいこと。'],
    ['勢力伯仲', 'せいりょくはくちゅう', '両者の力が優劣をつけにくいほど接近していること。'],
    ['周知徹底', 'しゅうちてってい', '全ての人にしっかりと知れ渡らせること。'],
    ['襲名披露', 'しゅうめいひろう', '先代などの名前や，家名を受け継いだことを多くの人に知らせること。'],
    ['斬新奇抜', 'ざんしんきばつ', '物事の思いつきがこれまでにないほど新しく，独特なこと。'],
    ['胆大心小', 'たんだいしんしょう', '物事を恐れずに行いながらも，小さなことに気を配ること。'],
    ['袖手傍観', 'しゅうしゅぼうかん', '隣で何も出来ずに，ただ見ていること。'],
    ['率先垂範', 'そっせんすいはん', '人の先頭に立って行動して，手本を示すこと。'],
    ['奮励努力', 'ふんれいどりょく', '気力を充実させ，力を尽くして取り組むこと。'],
    ['粒粒辛苦', 'りゅうりゅうしんく', '努力や苦労を少しずつ積み重ねていくこと。'],
    ['危機一髪', 'ききいっぱつ', '髪の毛一本ほどのわずかな差で，大変なことになる瞬間や状況という意味。'],
    ['針小棒大', 'しんしょうぼうだい', 'たいしたことがない物事を，実際より大げさに言うこと。'],
    ['文人墨客', 'ぶんじんぼっかく', '詩文や書画などの風雅なものに携わる人のこと。'],
    ['意味深長', 'いみしんちょう', '意味が深く含みが多くあること。'],
    ['外柔内剛', 'がいじゅうないごう', '外見は穏やかそうに見えるが，実際は意志が強いこと。'],
    ['枝葉末節', 'しようまっせつ', '本質からはずれたささいなこと。どうでもよい部分。'],
    ['和衷協同', 'わちゅうきょうどう', '心を同じくしてともに力を合わせること。'],
    ['神出鬼没', 'しんしゅつきぼつ', '自由に現れたり隠れたりすること。'],
    ['沙羅双樹', 'さらそうじゅ', '八十歳で入滅した釈迦の寝床にあった沙羅の木のこと。'],
    ['夏炉冬扇', 'かろとうせん', '夏の火鉢と冬の扇のことで，無用なもの，役に立たないもののたとえ。'],
    ['衆人環視', 'しゅうじんかんし', '多くの人がまわりで見ていること。'],
    ['意気阻喪', 'いきそそう', '意気込みがくじけ弱り，元気を失うこと。'],
    ['深山幽谷', 'しんざんゆうこく', '人が踏み入れていない，奥深く静かな自然のこと。'],
    ['旧態依然', 'きゅうたいいぜん', '昔の状態のまま，進歩や変化が全くないこと。'],
    ['拍手喝采', 'はくしゅかっさい', '拍手をしながら大きな声で褒めたてること。'],
    ['臨機応変', 'りんきおうへん', '状況の変化に応じて対処する方法を適切にかえること。'],
    ['教唆扇動', 'きょうさせんどう', '教えそそのかして人をたきつけ，行動するように気持ちを湧き立たせること。'],
    ['緩急自在', 'かんきゅうじざい', '物事を思いのままに操ること。'],
    ['抑揚頓挫', 'よくようとんざ', '言葉の調子を上下させること。'],
    ['春宵一刻', 'しゅんしょういっこく', '春の夜は何よりも趣深く，その一刻は何ものにもかえがたい価値があるということ。'],
    ['急転直下', 'きゅうてんちょっか', '物事があまり良くない状態から急に良い状態に変わり，解決に向かうこと。'],
    ['朝令暮改', 'ちょうれいぼかい', '命令や法令がすぐに変わって定まらないこと。'],
    ['面従腹背', 'めんじゅうふくはい', '表面だけ服従するふりをして，内心は反抗していること。'],
    ['安寧秩序', 'あんねいちつじょ', '社会が落ち着いていて，公共の安全や社会の秩序が保たれていること。'],
    ['眉目秀麗', 'びもくしゅうれい', '顔立ちが美しく整っている様子。'],
    ['平身低頭', 'へいしんていとう', '身をかがめて頭を低く下げ，ひたすら畏まること。'],
    ['唯唯諾諾', 'いいだくだく', '何事も迷わず他人の言いなりになるさま。'],
    ['白砂青松', 'はくしゃせいしょう', '海岸の美しい景色を言い表す言葉。'],
    ['無為徒食', 'むいとしょく', '何もしないで，ただぶらぶらと日を過ごすこと。'],
    ['取捨選択', 'しゅしゃせんたく', '必要なものだけを取って，不必要なものを捨てること。'],
    ['暖衣飽食', 'だんいほうしょく', '物質的に何の不足もない満ち足りた生活。'],
    ['同床異夢', 'どうしょういむ', '立場や仕事が同じでも，考え方や目的が違うこと。'],
    ['和魂洋才', 'わこんようさい', '日本古来の精神を大切にしつつ，西洋の学問や知識を学ぶこと。'],
    ['謹厳実直', 'きんげんじっちょく', '極めて慎み深く誠実で正直なこと。'],
    ['内疎外親', 'ないそがいしん', '外見は親しそうにしているが，内心で疎んじんでいること。'],
    ['空中楼閣', 'くうちゅうろうかく', '根拠のないこと，現実性に欠けることのたとえ。'],
    ['名誉毀損', 'めいよきそん', '他人の名誉を傷つけること。'],
    ['縦横無尽', 'じゅうおうむじん', '自由自在に振る舞うさま。'],
    ['順風満帆', 'じゅんぷうまんぱん', '特に問題が起きることなく，思い通りに物事が進んでいくこと。'],
    ['離合集散', 'りごうしゅうさん', '離れては集まり，集まっては離れたりすること。'],
    ['誇大妄想', 'こだいもうそう', '自分の現在の状態を大げさに空想すること。'],
    ['初志貫徹', 'しょしかんてつ', '初めに思い立った願望や志を最後まで貫き通すこと。'],
    ['進取果敢', 'しんしゅかかん', '自身の意思で積極的に取り組み，大きな決断力を持って，失敗を恐れずに取り組むこと。'],
    ['精進潔斎', 'しょうじんけっさい', '肉食や飲酒を断ち，行いを慎んで身を清めること。'],
    ['百八煩悩', 'ひゃくはちぼんのう', '人間の持つ苦しみを生み出す全ての迷いのこと。'],
    ['普遍妥当', 'ふへんだとう', 'どんな場合にも真理として承認されること。'],
    ['禍福得喪', 'かふくとくそう', 'わざわいにあたり，幸いにあったり，成功して出世したり，位を失ったりすること。'],
    ['紳士淑女', 'しんししゅくじょ', '高い教養と品格を持った，礼儀正しい立派な男性と女性。'],
    ['凄凄切切', 'せいせいせつせつ', '極めて物寂しいこと。'],
    ['妖怪変化', 'ようかいへんげ', '人知を超えた怪しい化け物のこと。'],
    ['情状酌量', 'じょうじょうしゃくりょう', '判決にあたり，同情できる事情を考えに入れて刑罰を軽くすること。'],
    ['鬱鬱勃勃', 'うつうつぼつぼつ', '生気が満ちる様子のこと。'],
    ['月下氷人', 'げっかひょうじん', '婚姻の媒酌人。'],
    ['金城湯池', 'きんじょうとうち', '守りが堅固で攻めるのが難しい城。'],
    ['高論卓説', 'こうろんたくせつ', 'この上なくすぐれた意見や議論のこと。'],
    ['無病息災', 'むびょうそくさい', '\t病気をすることなく，健康で元気に暮らすこと。'],
    ['巧言令色', 'こうげんれいしょく', '愛想のよいことを言ったり，顔色をつくろって，人にこびへつらうこと。'],
    ['遺憾千万', 'いかんせんばん', '非常に残念で仕方ないこと。心残りこのうえない。くちおしくてならない。'],
    ['虚虚実実', 'きょきょじつじつ', '互いに計略や技の限りを尽くして戦うこと。'],
    ['博覧強記', 'はくらんきょうき', '様々な種類の書物を読み，それらの書物から得た知識をしっかりと記憶していること。'],
    ['本末転倒', 'ほんまつてんとう', '物事の重要な部分とそうでない部分を逆に捉えてしまうこと。'],
    ['興味津津', 'きょうみしんしん', '非常に関心があること。「津々」は，絶えずあふれ出るさま。'],
    ['遠慮会釈', 'えんりょえしゃく', '相手のことを考え，応対をつつましく控え目に行うこと。'],
    ['羊質虎皮', 'ようしつこひ', '見掛け倒しで中身が伴わないことのたとえ。'],
    ['片言隻語', 'へんげんせきご', '僅かな言葉，ほんのひと言ふた言。'],
    ['唯一無二', 'ゆいいつむに', 'ただそれ一つだけで二つとないこと。他にない貴重なものであること。'],
    ['喜色満面', 'きしょくまんめん', '嬉しそうな表情が顔全体に溢れ出ている様子。'],
    ['孤城落日', 'こじょうらくじつ', '零落して昔の勢いを失い，助けもなく心細いさま。'],
    ['南船北馬', 'なんせんほくば', '絶えず旅していること。'],
    ['晴耕雨読', 'せいこううどく', '田園で心静かにのんびりと気ままな生活をすること。'],
    ['直情径行', 'ちょくじょうけいこう', '自分の思ったとおりに振る舞うこと。'],
    ['大言壮語', 'たいげんそうご', '口では大きなことを言っても，実行がともなわないこと。'],
    ['英俊豪傑', 'えいしゅんごうけつ', '多くの中で特に優れた人物。'],
    ['竜頭蛇尾', 'りゅうとうだび', '始めは盛んな勢いがあるが，終わりに近づくにつれて勢いが落ちてしまうこと。'],
    ['汗牛充棟', 'かんぎゅうじゅうとう', '蔵書が非常に多いことのたとえ。'],
    ['金科玉条', 'きんかぎょくじょう', '自分の主張や立場などの絶対的なよりどころとなる教訓や信条。'],
    ['放歌高吟', 'ほうかこうぎん', '周囲を気にせず声高に詩歌を吟じること。'],
    ['千紫万紅', 'せんしばんこう', 'さまざまな色の花が咲き乱れている様子。'],
    ['浅学非才', 'せんがくひさい', '学問や知識が浅く，才能や能力が乏しいこと。'],
    ['論旨明快', 'ろんしめいかい', '議論や主張などの内容が分かりやすいこと。'],
    ['羞月閉花', 'しゅうげつへいか', '容姿の美しい女性のたとえ'],
    ['陶犬瓦鶏', 'とうけんがけい', '格好ばかりで役に立たないもののたとえ'],
    ['唯我独尊', 'ゆいがどくそん', '自分だけが優れているとうぬぼれること。'],
    ['気宇壮大', 'きうそうだい', '心構えや発想が大きく立派なこと。'],
    ['快刀乱麻', 'かいとうらんま', 'こじれた物事を手際よく処理すること。'],
    ['泰山北斗', 'たいざんほくと', 'その分野での第一人者。'],
    ['昼夜兼行', 'ちゅうやけんこう', '休まず物事をし続けること。'],
    ['酔生夢死', 'すいせいむし', '何をなすこともなく，ぼんやりと生涯を過ごすこと。'],
    ['堆金積玉', 'たいきんせきぎょく', '非常に多くの富を集めること。'],
    ['万緑一紅', 'ばんりょくいっこう', '多くの中で目立って優れているもの。'],
    ['異端邪説', 'いたんじゃせつ', '正統から外れている思想・信仰・学説。'],
    ['錦上添花', 'きんじょうてんか', '美しいものの上にさらに美しいものを加えること。'],
    ['質実剛健', 'しつじつごうけん', '飾り気がなく真面目で，心身ともに強くたくましいこと。'],
    ['生殺与奪', 'せいさつよだつ', '他のものを自分の思うままに支配すること。'],
    ['比翼連理', 'ひよくれんり', '男女の情愛が深く，仲むつまじいことのたとえ。'],
    ['合従連衡', 'がっしょうれんこう', '状況に応じて結びついたり離れたりすること。'],
    ['甘言蜜語', 'かんげんみつご', '相手に取り入れるための甘い言葉。'],
    ['流言飛語', 'りゅうげんひご', '確かな根拠のない，いい加減なうわさ。'],
    ['四分五裂', 'しぶんごれつ', 'ばらばらになること。'],
    ['気炎万丈', 'きえんばんじょう', '他を圧倒するほど威勢がよいこと。'],
    ['阿鼻叫喚', 'あびきょうかん', '非常に悲惨でむごたらしいさまのたとえ。'],
    ['意志疎通', 'いしそつう', 'お互いの考えがよどみなくよく通じること。'],
    ['五臓六腑', 'ごぞうろっぷ', '心臓や肺などの臓器と胃や腸などの器官。体内全体のこと。'],
    ['懇切丁寧', 'こんせつていねい', '親切でこまかく気配りのあること。'],
    ['自己陶酔', 'じことうすい', '自分で満ち足りた思いになり，うっとりと酔う気持ち。'],
    ['熟慮断行', 'じゅくりょだんこう', '十分に時間をかけて考えた上で，思いきって実行すること。'],
    ['女性遍歴', 'じょせいへんれき', '多くの女性と経験を重ねること。'],
    ['知勇兼備', 'ちゆうけんび', '知恵と勇気をともに持っていること。'],
    ['津津浦浦', 'つつうらうら', 'いたるところの港や海岸。全国くまなくいたる所の意味。'],
    ['手枷足枷', 'てかせあしかせ', '自由な行動を束縛するもの。'],
    ['丁稚奉公', 'でっちぼうこう', '少年が一定期間，雑役で奉公すること。'],
    ['大願成就', 'たいがんじょうじゅ', '大きな願いが遂げられること。大願が神仏の加護によってかなえられること。'],
    ['徹底抗戦', 'てっていこうせん', '相手にとことん手向かって戦うこと。'],
    ['徹頭徹尾', 'てっとうてつび', '最初から最後まで。どこまでも一つの考えや方針などを，徹底して貫くさま。'],
    ['東奔西走', 'とうほんせいそう', '仕事や用事のため，あちらこちらに，なにかと忙しく駆け回ること。'],
    ['内柔外剛', 'ないじゅうがいごう', '本当は気が弱いのに外の態度だけ強そうに見せること。'],
    ['被害妄想', 'ひがいもうそう', 'ありもしない危害を加えられていると思い込むこと。'],
    ['百戦錬磨', 'ひゃくせんれんま', '多くの経験を積んで技術や才能を向上・錬成すること。'],
    ['乱脈経営', 'らんみゃくけいえい', '筋道の立たない，でたらめな経営の仕方。'],
    ['和洋折衷', 'わようせっちゅう', '日本と西洋の二つの風俗，様式を適当に取り合わせること。'],
    ['一言居士', 'いちげんこじ', 'ことあるごとに一言いわないと気のすまない人のことをいう。'],
    ['自己欺瞞', 'じこぎまん', '自分で自分をあざむき，だますこと。'],
    ['不惜身命', 'ふしゃくしんみょう', '自分の身をささげて惜しまないことで，死をもいとわない決意をいう。'],
    ['無学文盲', 'むがくもんもう', '学問が無く，字も読めないこと。'],
    ['頑固一徹', 'がんこいってつ', '自分の考えをいとすじにに思い込み，曲げようとしないで押し通すさま。また，そういう性格。'],
    ['残酷非道', 'ざんこくひどう', 'むごたらしくて，人道に背いた行い。'],
    ['自由奔放', 'じゆうほんぽう', '気がね無しに，何にもとらわれず，自分の思うがままに振る舞うこと。'],
    ['盛者必衰', 'じょうしゃひっすい', 'この世では盛大に栄えている者も，いつか必ず衰える。仏教の人生観でこの世は無常である。'],
    ['相碁井目', 'あいごせいもく', '何事につけても人の実力は上下様々，バラエティーに富んでいるということ。'],
    ['一切衆生', 'いっさいしゅじょう', 'この世に生を受けたあらゆるもの。生きとし生けるもの。'],
    ['意馬心猿', 'いばしんえん', '心に煩悩や欲情が盛んに動いて，心中の鎮まらないこと。'],
    ['会者定離', 'えしゃじょうり', '会う者はかならず，はなれる。この世は無常であることのたとえ。'],
    ['寛仁大度', 'かんじんたいど', '心が広くて慈悲深く，度量が大きいこと。'],
    ['興味索然', 'きょうみさくぜん', '関心が薄れていくこと。物足りなくて面白みがない。.'],
    ['苦尽甘来', 'くじんかんらい', '苦去りて，楽来たる。苦しい時が去って，やっと楽しい日が訪れること。'],
    ['生死流転', 'しょうじるてん', '生と死をくりかえすこと。万物が絶えず形を変えて生まれ変わること。'],
    ['大兵肥満', 'だいひょうひまん', '体が大きくて太っている男性のこと。'],
    ['百花斉放', 'ひゃっかせいほう', '多くの花が一斉に開くこと。学問や芸術が，自由に盛んに行われること。'],
    ['一子相伝', 'いっしそうでん', '自分の子ひとりだけに奥義を伝えること。'],
    ['怨親平等', 'おんしんびょうどう', '敵も味方も同じように処遇すること。'],
    ['天下御免', 'てんかごめん', '公然と許されること。'],
    ['閉月羞花', 'へいげつしゅうか', '女性の美貌のたとえ。'],
    ['失望落胆', 'しつぼうらくたん', '希望を失って，がっかりすること。'],
    ['終始一貫', 'しゅうしいっかん', '始めから終わりまで一筋に，態度・行動を変えないこと。'],
    ['理非曲直', 'りひきょくちょく', '物事の正しいこととそうでないこと。'],
    ['簡単明瞭', 'かんたんめいりょう', '極めて分かりやすいこと。'],
    ['殺生禁断', 'せっしょうきんだん', '生き物を殺すのを禁じること。'],
    ['思慮分別', 'しりょふんべつ', '物事を慎重に考えて判断すること。'],
    ['雄心勃勃', 'ゆうしんぼつぼつ', '雄々しい勇気が溢れ出してくること。'],
    ['虎渓三笑', 'こけいさんしょう', 'ある物事に熱中するあまり，他のことをすべて忘れてしまうこと。'],
    ['延命息災', 'えんめいそくさい', '達者で長生きすること。'],
    ['主客転倒', 'しゅかくてんとう', '物事の順序や立場などが逆転すること。'],
    ['犬牙相制', 'けんがそうせい', '国境が複雑に入り組んで接していること。'],
    ['円転滑脱', 'えんてんかつだつ', '物事が滞りなく進むこと。'],
    ['是非曲直', 'ぜひきょくちょく', '物事の善悪・正不正のこと。'],
    ['盲亀浮木', 'もうきふぼく', '会うことが非常に難しいこと，めったにないことのたとえ。'],
    ['和魂漢才', 'わこんかんさい', '日本固有の精神と中国伝来の学問が合すること。'],
    ['沃野千里', 'よくやせんり', 'よく肥えた平地がどこまでも広がっていること。'],
    ['読書百遍', 'どくしょひゃっぺん', '意味の難しい文章でも，何度も読むことで自然に理解できるということ。'],
    ['心頭滅却', 'しんとうめっきゃく', '気を散らすような考えを消し去ること。集中してことにあたれば，苦しさを感じないということ。'],
    ['先憂後楽', 'せんゆうこうらく', '為政者の心構えのたとえ。'],
    ['熱願冷諦', 'ねつがんれいてい', '熱意をもって真剣に願うことと，冷静になって本質を見きわめること。'],
    ['志操堅固', 'しそうけんご', '自分の理念や信念，流儀などを堅く守っていて決して変えることがないこと。'],
    ['論功行賞', 'ろんこうこうしょう', '功績や手柄を調べて，その程度に見合う褒美を与えること。'],
    ['冶金踊躍', 'やきんようやく', '自分が置かれている今の立場に満足できないこと。'],
    ['一日千秋', 'いちじつせんしゅう', '時，人，物などが来るのを今か今かと楽しみに待つこと。'],
    ['抜本塞源', 'ばっぽんそくげん', '災いの原因になるものを完全に取り除くこと。'],
    ['危急存亡', 'ききゅうそんぼう', '危険が迫っていて，生死の瀬戸際で危機的な状況のこと。']];

  const [currentQuestion, setcurrentQuestion] = useState(Math.floor(Math.random() * (0 - questions.length)) + questions.length);
  const [prevQuestion, setprevQuestion] = useState(0);
  const [Qcount, setQcount] = useState(0);
  const [th, setTh] = useState(Math.floor(Math.random() * 2));
  const [answer, setAnswer] = useState(questions[currentQuestion][0].substring(th * 2, (th * 2) + 2));
  const [correct, setCorrect] = useState(0);
  const [uAnswer, setuAnswer] = useState("");
  const [prevuAnswer, setprevuAnswer] = useState("");
  const [prevAnswer, setprevAnswer] = useState("");
  const [show, setShow] = useState(true);

  const ansubmit = () => {
    setprevQuestion(currentQuestion);
    const nextQuestion = Math.floor(Math.random() * (0 - questions.length)) + questions.length;
    setQcount(Qcount + 1);
    setcurrentQuestion(nextQuestion);
    setprevAnswer(answer);
    setTh(Math.floor(Math.floor(Math.random() * 2)));
    setAnswer(questions[nextQuestion][0].substring(th * 2, (th * 2) + 2));
    if (uAnswer !== "") {
      setprevuAnswer(uAnswer);
    }
    else if (uAnswer === "") {
      setprevuAnswer("[無回答]");
    }
    setuAnswer("");

    if (uAnswer === answer) {
      setCorrect(correct + 1);
    }
    else if (uAnswer.substring(1, 2) === "々") {
      if (uAnswer.substring(0, 1) + uAnswer.substring(0, 1) === answer) {
        setCorrect(correct + 1);
      }
    }
  };
  let alert = "";
  if (show) {
    alert = <Alert key='primary' variant='primary' onClose={() => setShow(false)} dismissible>
      収録問題は上部メニューのExcelファイルから閲覧できます。○○曲直のように複数解があることがありますが，どれか1つを解として出題します。そのため，実際に入力された四字熟語が存在しても不正解となる場合があります。
    </Alert>
  }
  else {
    alert = "";
  }

  return (
    <React.Fragment>
      <Header />
      {alert}
      <Quiz
        answer={answer}
        questions={questions}
        currentQuestion={currentQuestion}
        ansubmit={ansubmit}
        correct={correct}
        setuAnswer={setuAnswer}
        uAnswer={uAnswer}
        Qcount={Qcount}
        prevQuestion={prevQuestion}
        prevuAnswer={prevuAnswer}
        prevAnswer={prevAnswer}
      />
    </React.Fragment>
  );
}

export default App;